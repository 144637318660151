.table-row-expanded {
  .table-row-img {
    content: url('/assets/icons/minus-icon.svg');
  }

  .description-img {
    height: auto;
    display: block;
    visibility: visible;
    opacity: 1;
    transform: scaleY(1);
  }
}

.table-row-img {
  content: url('/assets/icons/plus-icon.svg');
}

.description-img {
  height: 0;
  display: block;
  opacity: 0;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.35s ease-out;
}

.table-row-item {
  .row-buttons,
  .row-container {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
  }
  @media screen and (max-width: 768px) {
    .row-container {
      @apply translate-x-0;
    }
    .row-buttons {
      @apply translate-x-20;
    }
  }
}


@media screen and (min-width: 768px) {
  .row-container {
    width: 80%
  }
  .row-buttons {
    width: 20%;
  }
  .table-row-item:hover {
    @apply flex items-start;
    .row-buttons {
      @apply flex block;
    }
  }
}

.show-buttons {
  .row-container {
    @apply -translate-x-20;
  }
  .row-buttons {
    @apply translate-x-0;
  }
}
